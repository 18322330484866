<template>
  <div class="ticket-date-box">
    <div class="ticket-date">
      <div class="ticket-date__datetime">
        <div class="ticket-date__datetime-item ticket-date__date">
          <div class="ticket-date__date-header">
            <Heading level="4">Вибір дати</Heading>
            <div class="ticket-date__date-month">
              Місяць: {{ currentMonth }}
            </div>
          </div>
          <date-picker
            v-model="selectedDate"
            type="date"
            lang="uk"
            inline
            show-time-header
            :disabled-date="notBeforeToday"
          ></date-picker>
        </div>
        <div class="ticket-date__datetime-item ticket-date__time">
          <div class="ticket-date__time-header">
            <Heading level="4">Година</Heading>
          </div>
          <div class="ticket-date__time-wrapper">
            <div v-if="timeSlots" class="ticket-date__time-inner">
              <button
                v-for="slot in timeSlots"
                :key="slot.interval"
                class="ticket-date__time-item"
                :class="{ active: slot.interval === selectedTime }"
                type="button"
                :disabled="!slot.available || slot.interval === selectedTime"
                @click="selectTimeInterval(slot)"
              >
                {{ slot.interval }}
              </button>
            </div>
            <Loader v-else />
          </div>
        </div>
      </div>
      <div class="ticket-date__message-wrapper">
        <Heading
          level="3"
          class="ticket-date__message"
          :class="{ disabled: !isSelectedDateTime }"
        >
          <template v-if="isSelectedDateTime">
            <span class="ticket-date__message-title">
              Обрана дата та час:
            </span>
            <span class="ticket-date__message-value">
              {{ selectedDatetimeMessage }}
            </span>
          </template>
          <template v-else>
            {{ $t('buy_ticket.choose_date_and_time') }}
          </template>
        </Heading>

        <SignupControlButtons
          :back-button-text="$t('signup.button.back')"
          :go-button-text="$t('signup.button.continue')"
          :is-disabled-go-btn="!isSelectedDateTime"
          @go-back="goBack"
          @go-on="goToPaymentChoice"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getDateFromString } from '@/library/dateFormat';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';

import SignupControlButtons from '@/components/Signup/SignupControlButtons/SignupControlButtons.vue';
import Heading from '@/elements/Heading/Heading.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    DatePicker,
    SignupControlButtons,
    Heading,
    Loader,
  },
  data() {
    return {
      currentTicket: getFromLS('new-ticket'),
      timeSlots: null,
      monthsList: [
        'Січень',
        'Лютий',
        'Березень',
        'Квітень',
        'Травень',
        'Червень',
        'Липень',
        'Серпень',
        'Вересень',
        'Жовтень',
        'Листопад',
        'Грудень',
      ],
      selectedDate: new Date(),
      selectedTime: '',
    };
  },
  computed: {
    isSelectedDateTime() {
      const formattedSelectedDate = this.selectedDate.toString();
      return !!formattedSelectedDate.length && !!this.selectedTime.length;
    },
    currentMonth() {
      const currentMonthIndex = this.selectedDate.getMonth();
      return this.monthsList[currentMonthIndex];
    },
    selectedDatetimeMessage() {
      const formattedDate = getDateFromString(this.selectedDate).date;
      return `${formattedDate}, ${this.selectedTime}`;
    },
    choosenDate() {
      const formattedDate = getDateFromString(this.selectedDate).date;
      return `${formattedDate}`;
    },
    formattedSelectedDate() {
      return this.choosenDate.split('.').reverse().join('-');
    },
    formattedSelectedDatetime() {
      const timeStart = this.selectedTime.slice(0, 5);

      return `${this.formattedSelectedDate} ${timeStart}`;
    },
    ticketInfoDateTime() {
      return {
        time_interval: this.selectedTime,
        datetime: this.formattedSelectedDatetime,
      };
    },
  },
  watch: {
    choosenDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetTime();
        this.fetchTimeSlots();
      }
    },
  },
  mounted() {
    this.fetchTimeSlots();
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    async fetchTimeSlots() {
      const res = await this.$store.dispatch('getTimeSlots', {
        date: this.formattedSelectedDate,
        typeAuto: this.currentTicket.type_auto,
        customsPost: this.currentTicket.customs_post,
      });
      this.timeSlots = res;
    },
    resetTime() {
      this.timeSlots = null;
      this.selectedTime = '';
    },
    async selectTimeInterval(slot) {
      this.selectedTime = slot.interval;

      await this.$store.dispatch('executeHoldSlot', {
        datetime: this.formattedSelectedDatetime,
        typeAuto: this.currentTicket.type_auto,
        customsPost: this.currentTicket.customs_post,
      });
    },
    updateNewTicketInLS() {
      if (isLSHasItem('new-ticket')) {
        const updatedNewTicket = {
          ...getFromLS('new-ticket'),
          ...this.ticketInfoDateTime,
        };
        setToLS('new-ticket', updatedNewTicket);
      }
    },
    goBack() {
      this.$router.push({ name: 'NewTicketInfo' });
    },
    goToPaymentChoice() {
      this.updateNewTicketInLS();
      this.$router.push({ name: 'NewTicketPaymentChoice' });
    },
  },
};
</script>
<style lang="sass">
.ticket-date .ticket-date__datetime-item .title
  margin-top: 0
  margin-bottom: 0

.ticket-date__datetime
  display: flex
  flex-wrap: wrap
  gap: 40px
  padding: 52px 0
  border-bottom: 1px solid var(--primary-color-light-grey-2)

  @media screen and (max-width: 1199px)
    flex-direction: column
    padding: 40px 0

  @media screen and (max-width: 600px)
    gap: 24px
    padding: 24px 0

.ticket-date__datetime-item
  flex: 0 0 calc(50% - 20px)
  display: flex
  flex-direction: column
  justify-content: space-between

.ticket-date__date-header,
.ticket-date__time-header
  margin-top: 0
  margin-bottom: 30px

  @media screen and (max-width: 600px)
    margin-bottom: 16px!important

.ticket-date__date-header
  display: flex
  align-items: center
  gap: 24px

  @media screen and (max-width: 600px)
    gap: 12px
    margin-top: 0

.ticket-date__time-header
  @media screen and (max-width: 1199px)
    margin-bottom: 40px

.ticket-date__date-month
  font-weight: 600
  font-size: 24px
  line-height: 32px
  color: var(--btn-color-blue)
  margin-top: 8px

  @media screen and (max-width: 991px)
    font-size: 18px
    line-height: 24px

  @media screen and (max-width: 767px)
    font-size: 16px
    line-height: 24px
    margin-top: 5px

// Date Picker
.mx-datepicker-main
  border: none
  background-color: transparent

.mx-datepicker-inline
  width: 100%

.mx-calendar
  width: 100%
  padding: 0

.mx-calendar-header
  display: none

.mx-calendar-content
  height: 100%

.mx-table
  display: flex
  flex-direction: column
  border-spacing: 8px 6px

  @media screen and (max-width: 1199px)
    border-spacing: 20px 8px

  @media screen and (max-width: 767px)
    border-spacing: 10px 6px

  @media screen and (max-width: 600px)
    border-spacing: 6px

  @media screen and (max-width: 350px)
    border-spacing: 6px 4px

.mx-table thead
  display: flex
  justify-content: center
  padding: 0 8px

.mx-table tbody,
.ticket-date__time-wrapper
  border: 1px solid var(--primary-color-light-grey-2)
  border-radius: var(--primary-border-radius)

.mx-table tbody
  display: flex
  flex-direction: column
  align-items: center
  padding: 34px 8px
  @media screen and (max-width: 1230px)
    padding: 34px 4px

  @media screen and (max-width: 1199px)
    border-left: none
    border-right: none
    border-radius: 0
    padding: 34px 0

  @media screen and (max-width: 600px)
    padding: 18px 0

.mx-table-date td, .mx-table-date th
  width: 75px
  color: var(--primary-color-dark-blue)

  @media screen and (max-width: 767px)
    width: 65px

  @media screen and (max-width: 600px)
    width: 42px

  @media screen and (max-width: 350px)
    width: 34px
    font-size: 15px!important

.mx-table-date .today
  color: var(--btn-color-blue)

.mx-table-date th
  font-weight: 600
  font-size: 18px
  text-transform: capitalize

  @media screen and (max-width: 600px)
    font-size: 16px

.mx-table-date td
  height: 62px
  font-size: 24px

  @media screen and (max-width: 767px)
    height: 52px
    font-size: 20px

  @media screen and (max-width: 600px)
    height: 40px
    font-size: 16px

  @media screen and (max-width: 350px)
    height: 34px

.mx-table-date td:not(.cell.not-current-month)
  border: 1px solid var(--primary-color-light-grey-2)
  box-shadow: 0px 3px 4px rgba(148, 157, 172, 0.12)
  border-radius: 8px

.mx-table-date td:not(.cell.not-current-month, .cell.disabled):hover
  border: 1px solid var(--btn-color-blue)
  color: var(--btn-color-blue)

.mx-table-date .cell.not-current-month
  color: var(--primary-color-dark-grey-2)

.mx-calendar-content .cell.disabled
  color: #ccc !important

.mx-calendar-content .cell.active
  background-color: var(--btn-color-blue)
  border: 1px solid var(--btn-color-blue)!important
  color: var(--primary-color-white)!important

.ticket-date__time-wrapper
  padding: 40px 16px 80px 16px
  min-height: 514px
  display: flex
  align-items: center
  justify-content: center

  @media screen and (max-width: 1199px)
    border: none
    padding: 0
    min-height: 392px

  @media screen and (max-width: 991px)
    min-height: 324px

  @media screen and (max-width: 767px)
    min-height: 528px

  @media screen and (max-width: 600px)
    min-height: 376px

.ticket-date__time-inner
  display: flex
  flex-wrap: wrap
  gap: 16px

  @media screen and (max-width: 600px)
    gap: 8px

.ticket-date__time-item
  display: flex
  align-items: center
  justify-content: center
  flex: 0 0 calc(25% - 12px)
  height: 52px
  cursor: pointer
  background-color: var(--primary-color-white)
  border-radius: var(--primary-border-radius)
  border: solid 1px var(--primary-color-light-grey-2)
  box-shadow: var(--primary-border-shadow-2)
  color: var(--primary-color-dark-blue)
  font-weight: 600
  font-size: 18px

  @media screen and (max-width: 1400px)
    font-size: 16px

  @media screen and (max-width: 991px)
    flex: 0 0 calc(20% - 13px)

  @media screen and (max-width: 767px)
    flex: 0 0 calc(33.33333% - 11px)

  @media screen and (max-width: 600px)
    flex: 0 0 calc(33.33333% - 6px)
    height: 40px
    font-size: 13px

  &[disabled]
    background-color: var(--primary-color-light-grey-6)
    color: var(--primary-color-dark-grey-2)
    cursor: default
    position: relative

    &:before
      background: url(/images/cross-lines.svg) no-repeat 50%
      background-size: contain
      position: absolute
      content: ""
      width: 100%
      height: 100%
      top: 0
      left: 0

.ticket-date__time-item.active
  background: var(--btn-color-blue)
  color: var(--primary-color-white)

.ticket-date__time-item[disabled].active
  border-color: var(--btn-color-blue)
  &:before
    display: none

.ticket-date__time-item:not([disabled]):hover
  border-color: var(--btn-color-blue)


.ticket-date__message-wrapper
  display: flex
  flex-direction: column
  align-items: center

  .signup-control-buttons
    width: 100%

.ticket-date__message
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  font-size: 40px!important
  line-height: 48px!important
  margin-top: 48px!important
  margin-bottom: 0!important

  @media screen and (max-width: 1199px)
    font-size: 40px

  @media screen and (max-width: 991px)
    margin-top: 40px!important
    font-size: 32px !important
    line-height: 40px !important

  @media screen and (max-width: 600px)
    margin-top: 24px!important
    font-size: 24px !important
    line-height: 32px !important

.ticket-date__message
  &.disabled
    color: var(--primary-color-light-grey-8)

.ticket-date__message-value
  color: var(--btn-color-blue)
</style>
